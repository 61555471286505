import React, { useEffect, useState } from 'react';
import Header from '../../src/components/global/dark-header'
import Footer from '../../src/components/global/dark-footer'
import SectionProducts from '../components/organism/product/section-products2';
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import db from '../firebase';
import DarkLayout from '../layout/dark-default';
import SectionSlider from '../components/organism/product/section-slider';
import SectionMasthead from '../components/organism/product/section-masthead';
import SEO from '../layout/SEO';

const Technical = () => {
    const [technical, setTechnical] = useState([]);
    const [image1, setImage1] = useState([]);
    const [image2, setImage2] = useState([]);
    const storage = getStorage();

    useEffect(() => {
        db.collection('Technical').onSnapshot(snapshot => {
            setTechnical(snapshot.docs.map(doc => ({
                id: doc.id,
                canonical_url: doc.data().canonical_url,
                description: doc.data().description,
                meta_desc: doc.data().meta_desc,
                meta_title: doc.data().meta_title,
                og_image: doc.data().og_image,
                product_slider_image: doc.data().product_slider_image,
                slug: doc.data().slug,
                technical_product: doc.data().technical_product,
                title: doc.data().title
            })))
        })
    }, [])

    useEffect(() => {
        technical[0] && technical[0].product_slider_image.map(image => {
            const imagaRef = ref(storage, image);
            setTimeout(() => {
                getDownloadURL(imagaRef).then(url => {
                    setImage1((prev) => [...prev, url]);
                })
            }, 10);
        })
    }, [technical])

    useEffect(() => {
        technical[0] && technical[0].technical_product.map(image => {
            const imagaRef = ref(storage, image);
            setTimeout(() => {
                getDownloadURL(imagaRef).then(url => {
                    setImage2((prev) => [...prev, url]);
                })
            }, 100);
        })
    }, [technical])

    console.log(image1, image2, "valid technical data");


    return (
        <>
            {technical[0] && <SEO canonicalUrl={technical[0].canonical_url} title={technical[0].title} meta_desc={technical[0].meta_desc} />}
            <DarkLayout>
                {technical[0] && <SectionMasthead technical title={technical[0].title} desc={technical[0].description} subprod="section_sub_pb" />}
                <SectionSlider bp imgUrl={image1} />
                <SectionProducts technical products={image2} />
            </DarkLayout>
        </>
    )
}

export default Technical